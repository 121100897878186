import { useCurrentPostTypeSingleData } from 'src/services/api/queries/hooks/useCurrentPostTypeSingleData';
import { useColors } from 'src/context/colors.context';

export const usePageColors = () => {
  const pageFromSlugDataProduct = useCurrentPostTypeSingleData('product');
  const pageFromSlugDataStudentArea =
    useCurrentPostTypeSingleData('student-area');
  const pageFromSlugDataNOB = useCurrentPostTypeSingleData('nob');
  const postType =
    pageFromSlugDataProduct?.type ||
    pageFromSlugDataStudentArea?.type ||
    pageFromSlugDataNOB?.type;
  const { conferenceColors, productColors } = useColors();

  if (postType === 'product' || postType === 'student-area') {
    return productColors;
  }

  if (postType === 'nob') {
    return {
      main: conferenceColors?.primary,
      washed: conferenceColors?.secondary,
    };
  }

  return undefined;
};
