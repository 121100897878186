import Button from '@components/views/Button';
import Container from '@components/containers/Container';
import DownloadIcon from '../../../assets/images/icons/download.svg';
import DownloadPattern from '../../../assets/patterns/download-pattern.svg';
import GlossyBanner from '@components/views/GlossyBanner';
import Image from 'next/image';
import React from 'react';
import Squares from 'src/assets/images/Squares2.png';
import { IButtonACF } from 'src/types/IButtonACF';
import { theme } from '@styles/theme';
import HeadingStroke, {
  HeadingStrokeProps,
} from '@components/views/HeadingStroke';
import GlossyButton from '@components/containers/GlossyButton';
import Link from 'next/link';
import { usePageColors } from 'src/hooks/usePageColors';

export interface DownloadSectionProps {
  heading: HeadingStrokeProps;
  sectionTitle: string;
  button: IButtonACF;
  icon: string;
  link?: string;
}

const DownloadSection: React.FunctionComponent<DownloadSectionProps> = ({
  heading,
  sectionTitle,
  button,
  icon,
  link,
}) => {
  const colors = usePageColors();

  const DownloadIconComponent = link ? (
    <Link href={link}>
      <a className="hover:opacity-80">
        <DownloadIcon fill={colors?.main || theme.colors.primary.DEFAULT} />
      </a>
    </Link>
  ) : (
    <DownloadIcon fill={colors?.main || theme.colors.primary.DEFAULT} />
  );

  return (
    <section
      className="relative"
      style={{
        backgroundColor: colors?.washed as string,
      }}
    >
      <Container className="relative z-10">
        <div className="grid md:grid-cols-[60%_40%]">
          <div className="py-48 md:py-96 max-w-568 relative z-10">
            <div
              className="text-neutral-5 mb-8"
              dangerouslySetInnerHTML={{ __html: sectionTitle }}
            />
            <div className="mb-40">
              <HeadingStroke
                {...heading}
                decorationColor={colors?.main || theme.colors.primary.DEFAULT}
                textColor="#EFF2FB"
              />
            </div>
            <div>
              <Button {...button} href={link || button.url}>
                {button.title}
              </Button>
            </div>
          </div>
          <div className="flex justify-center items-center pb-48 md:pb-0 relative">
            <div className="relative">
              {DownloadIconComponent}
              <div className="absolute bottom-0 right-0 transform md:translate-x-2/3 translate-y-1/2 min-w-[14.4rem]">
                <GlossyButton image={icon} href={link} secondary={false} />
              </div>
              <div className="absolute top-0 left-0 transform -translate-x-[110%] translate-y-1/2 w-88 h-88 hidden lg:grid">
                <GlossyBanner customBackground="linear-gradient(126.6deg, rgba(211, 211, 211, 0.24) 28.69%, rgba(255, 255, 255, 0.048) 100%);" />
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="absolute w-full md:w-1/2 xxl:w-[45%] xxxl:w-1/3 h-full top-0 right-0">
        <Image layout="fill" src={Squares} alt="" />
      </div>
      <div className="absolute bottom-24 right-0 md:right-[40%]">
        <DownloadPattern
          fill={colors?.main || theme.colors.primary.DEFAULT}
          stroke={colors?.main || theme.colors.primary.DEFAULT}
        />
      </div>
    </section>
  );
};
export default DownloadSection;
