import { IPostTypes } from '../../types/IPostTypes';
import { usePostTypeCollection } from './usePostTypeCollection';
import { useRouter } from 'next/router';

/**
 *  Get data from current post by slug
 * @returns
 */
export function useCurrentPostTypeSingleData(postType: IPostTypes) {
  const router = useRouter();
  const slug = router.asPath;

  const pageFromSlugData = usePostTypeCollection(
    postType,
    {
      permalink: slug,
    },
    {
      enabled: !!slug,
    },
  ).data?.data[0];

  return pageFromSlugData;
}
