import React from 'react';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import ContainImage from '@components/views/ContainImage';
import { ProductColors, useColors } from 'src/context/colors.context';

export type GlossyBannerProps = {
  title?: string;
  image?: string;
  secondary?: boolean;
  secondaryBackground?: boolean;
  customBackground?: string;
  withHover?: boolean;
};

const StyledGlossyBanner = styled.div<{
  secondaryBackground: GlossyBannerProps['secondaryBackground'];
  customBackground: GlossyBannerProps['customBackground'];
  productColors?: ProductColors;
}>`
  ${({ secondaryBackground }) =>
    secondaryBackground
      ? css`
          background: linear-gradient(
            126.6deg,
            rgba(255, 255, 255, 0.24) 28.69%,
            rgba(0, 163, 255, 0.048) 100%
          );
        `
      : css`
          background: linear-gradient(
            126.6deg,
            rgba(196, 196, 196, 0.24) 28.69%,
            rgba(90, 90, 90, 0.048) 100%
          );
        `}

  ${({ customBackground }) =>
    customBackground &&
    css`
      background: ${customBackground};
    `}

  /* firefox hasn't support for backdrop-blur property */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    ${({ productColors }) =>
      productColors?.washed
        ? css`
            background: ${productColors.washed}CD;
          `
        : css`
            background: rgba(19, 26, 32, 0.7);
          `};
  }
`;

const GlossyBanner: React.FunctionComponent<GlossyBannerProps> = ({
  title,
  image,
  secondary,
  secondaryBackground,
  customBackground,
  withHover,
}) => {
  const { productColors } = useColors();
  return (
    //firefox hasn't support for backdrop-blur property
    <StyledGlossyBanner
      className={clsx('text-neutral-0 my-8 backdrop-blur-lg text-base group', {
        'xl:my-0 p-16 xl:py-27 xl:px-24': !secondary,
        'flex justify-center items-center h-full px-32': secondary,
      })}
      secondaryBackground={secondaryBackground}
      customBackground={customBackground}
      productColors={productColors}
    >
      {title && <span dangerouslySetInnerHTML={{ __html: title }} />}
      {!!image && (
        <ContainImage
          src={image}
          className={clsx({
            'transition-transform transform group-hover:scale-105': withHover,
          })}
        />
      )}
    </StyledGlossyBanner>
  );
};

export default GlossyBanner;
