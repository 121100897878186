import { IPagesParams } from '../types/IPages';
import { IPostTypes } from '../types/IPostTypes';
import axiosInstance from './axiosInstance';

/**
 * {@link https://developer.wordpress.org/rest-api/reference}
 */
class PostTypeCollectionApi {
  basePath = 'wp/v2/';

  async getAll(
    postType: IPostTypes,
    params?: Partial<IPagesParams>,
    signal?: AbortSignal,
  ) {
    return axiosInstance.get(this.basePath + postType, {
      params,
      signal,
    });
  }
}

export default PostTypeCollectionApi;
