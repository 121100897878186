import styled, { css } from 'styled-components';
import React from 'react';

export interface HeadingStrokeProps {
  level?: keyof JSX.IntrinsicElements;
  title: string;
  decorationColor?: string;
  textColor?: string;
  className?: string;
}

const StyledWrapper = styled.span<{
  decorationColor?: HeadingStrokeProps['decorationColor'];
  textColor?: HeadingStrokeProps['textColor'];
}>`
  span {
    color: ${(props: any) => props.textColor};
    ${(props) =>
      props.decorationColor &&
      css`
        background: ${(props: any) => props.decorationColor};
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
      `}
  }
`;

const HeadingStroke: React.FunctionComponent<HeadingStrokeProps> = ({
  title,
  decorationColor,
  level: Tag = 'h2',
  className = 'text-5xl font-extrabold',
  textColor = 'white',
}) => {
  return (
    <Tag>
      <StyledWrapper
        className={className}
        dangerouslySetInnerHTML={{ __html: title }}
        decorationColor={decorationColor}
        textColor={textColor}
      />
    </Tag>
  );
};

export default HeadingStroke;
