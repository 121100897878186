import React from 'react';
import GlossyBanner from '@components/views/GlossyBanner';

export interface GlossyButtonProps {
  href?: string;
  image?: string;
  title?: string;
  secondary?: boolean;
}

const GlossyButton: React.FunctionComponent<GlossyButtonProps> = ({
  href,
  image,
  title,
  secondary = true,
}) => {
  if (!href) {
    return <GlossyBanner image={image} title={title} secondary={secondary} />;
  }

  return (
    <a href={href}>
      <GlossyBanner
        image={image}
        title={title}
        secondary={secondary}
        withHover
      />
    </a>
  );
};
export default GlossyButton;
